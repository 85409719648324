<template>
  <div class="content">
    <div class="refundTitle">退票申请</div>
    <div class="tickedTimeDetail">
      <div class="AviationInfo">
        <p><span>{{ rtFlagText }}</span></p>
        <img :src="ticketInfo.logo" alt="" style="margin-left: 17px;height: 17px;">
        <p>{{ ticketInfo.airCompanyName }}{{ ticketInfo.flightNo }}</p>
        <p>{{ ticketInfo.date }}</p>
        <p>{{ ticketInfo.depTimeW }}</p>
      </div>
      <div class="TimeInfo">
        <div class="stareTimeInfo">
          <p>{{ ticketInfo.depTime }}</p>
          <p>{{ ticketInfo.startCity }}{{ ticketInfo.depAirportName }}</p>
        </div>
        <div class="TimeImg">
          <img
            src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/21/yuelvhuiLoDKVslO4A1609163787.png"
            alt=""
          >
        </div>
        <div class="endTimeInfo">
          <p>{{ ticketInfo.arrTime }}</p>
          <p>{{ ticketInfo.endCity }}{{ ticketInfo.arrAirportName }}</p>
        </div>
      </div>
    </div>
    <div class="van-hairline--top" />
    <div class="personList">
      <p class="title">乘机人</p>
      <div class="personListWrap">
        <div v-for="(item,index) in personList" :key="index" class="personLists">
          <p>{{ item.name }}</p>
          <p>{{ item.idTypeText }}<span>{{ item.idCard }}</span></p>
        </div>
      </div>
    </div>
    <div class="refundNextStep">
      <div class="ProjectedRefundPrice">预计退款<span>¥{{ totalPrice }}</span></div>
      <div class="goNext" @click="SubRefund">提交退票</div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { ActionSheet, Calendar, Icon, Loading, Sidebar, SidebarItem, Toast, TreeSelect } from 'vant'

Vue.use(Icon)
  .use(Calendar)
  .use(Loading)
  .use(Toast)
  .use(ActionSheet)
  .use(Sidebar)
  .use(SidebarItem)
  .use(TreeSelect)

import {
  setRefund
} from '@/services/comeOn.js'

export default {
  name: 'RefundConfirmation',
  data() {
    return {
      selRefundTravelList: [], // 选中的行程
      personList: [], // 选中的乘客
      totalPrice: 0, // 预计退款总额
      orderNo: '', // 订单号
      ticketIds: '', // 机票id
      rtFlagText: '', // 行程类型
      ticketInfo: {
        logo: '', // logo
        airCompanyName: '', // 航空名称
        flightNo: '', // 航空号码
        date: '', // 日期
        depTimeW: ''// 周几
      }
    }
  },
  created() {

  },
  mounted() {
    this.selRefundTravelList = JSON.parse(window.localStorage.getItem('selRefundTravelList'))// 选中的行程
    this.personList = JSON.parse(window.localStorage.getItem('selPersonList'))// 选中的乘客
    this.totalPrice = this.$route.query ? this.$route.query.totalPrice : this.$route.query.totalPrice// 退款总金额
    this.ticketInfo = this.selRefundTravelList[0]// 行程
    this.rtFlagText = this.$route.query ? this.$route.query.rtFlagText : ''// 单程，去程，返程
    this.ticketIds = (this.personList)[0].ticketId// 机票id
    this.orderNo = this.$route.query ? this.$route.query.orderNo : this.$route.query.orderNo// 订单号
  },
  methods: {
    // 提交退票
    SubRefund() {
      setRefund({
        orderNo: this.orderNo, // 订单编号
        applyType: 0, // 是否自愿0自愿1非自愿
        // reason:'',//原因说明，非自愿必填
        // refundType:'',//退款类型，非自愿必填
        ticketIds: this.ticketIds// 机票ID
      }).then(res => {
        if (Number(res.code) === 200) {
          Toast.success(res.msg)
          this.$router.push({
            path: '/ticketOrderDetail',
            query: {
              orderNo: this.orderNo
            }
          })
        } else {
          this.$router.push({
            path: '/TicketOrder'
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
html, body, .content {
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
}

.content {
  position: absolute;
  text-align: left;
  /*title*/

  .refundTitle {
    width: 100%;
    height: 44px;
    line-height: 44px;
    text-align: center;
    font-size: 17px;
    color: #333333;
    background-color: #fff;
  }

  /*行程详情*/

  .tickedTimeDetail {
    padding: 31px 23px;
    background-color: #fff;
    /*margin-top: 43px;*/

    .AviationInfo {
      display: flex;

      p {
        line-height: 17px;
        font-size: 13px;
        color: #333333;
      }

      p:nth-child(1) {
        width: 32px;
        height: 17px;
        text-align: center;
        background-color: #E33F44;
        color: #ffffff;
        border-radius: 3px;

        span {
          display: inline-block;
          transform: scale(0.8);
        }
      }

      p:nth-child(2) {
        margin-left: 28px;
      }

      p:nth-child(3) {
        margin-left: 10px;
      }

      p:nth-child(4) {
        margin-left: 10px;
      }
    }

    .TimeInfo {
      display: flex;
      margin-top: 20px;

      .stareTimeInfo, .endTimeInfo {
        color: #333333;
        flex: 1;

        p:nth-child(1) {
          font-size: 29px;
          font-weight: 600;
        }

        p:nth-child(2) {
          font-size: 12px;
        }
      }

      .TimeImg {
        flex: 1;

        img {
          width: 52px;
          height: 6px;
          margin: 14px 28px;
        }
      }
    }
  }

  /*乘机人列表*/

  .personList {
    padding: 13px;
    background-color: #fff;

    .title {
      font-size: 17px;
      color: #333333;
      font-weight: 600;
    }

    .personListWrap {
      margin-top: 7px;

      .personLists {
        margin-top: 13px;
        color: #333333;

        p:nth-child(1) {
          font-size: 17px;
          font-weight: 600;
        }

        p:nth-child(2) {
          font-size: 14px;
          margin-top: 14px;

          span {
            margin-left: 10px;
          }
        }
      }
    }
  }

  /*提交退票*/

  .refundNextStep {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 75px;
    text-align: left;
    display: flex;
    padding: 17px 13px;
    background-color: #ffffff;

    .ProjectedRefundPrice {
      height: 40px;
      line-height: 40px;
      font-size: 18px;
      font-weight: 600;

      span {
        color: #E33F44;
        margin-left: 10px;
      }
    }

    .goNext {
      margin-left: auto;
      width: 100px;
      height: 40px;
      background-color: #E33F44;
      text-align: center;
      line-height: 40px;
      font-size: 17px;
      color: #ffffff;
      border-radius: 20px;
    }
  }
}
</style>
